import isServerSide from '@helpers/misc/isServerSide'
import useEffect from '@hooks/useEffect'
import {useRouter} from 'next/router'

export default function Redirect(props) {
  const {url} = props
  const router = useRouter()
  useEffect(() => {
    if (url.startsWith('/')) {
      router.replace(url)
    } else {
      if (isServerSide()) return () => {}
      window.location.href = url
    }
  }, [])
  return null
}
